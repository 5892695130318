.labelStyle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.textStyle {
  font-size: 14px;
  color: green;
}
.mr-5 {
  margin-right: 5px;
}
.answer-feedback {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.d-flex {
  display: flex;
}
