.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo span {
  font-size: 1.2rem;
  font-weight: bold;
}

.logo small {
  font-size: 0.8rem;
}
