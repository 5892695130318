.answer-sheet-modal {
  display: flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-buttons {
  /* Customize styles for the button container */
  display: flex;
  justify-content: space-between;
}

.modal-buttons ion-button {
  /* Customize styles for IonButtons inside the container */
  flex: 1; /* Equal width for buttons */
  margin: 0 5px;
}

ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
}