.navbar {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}
