.custom-text-color {
  --ion-color-base: #797979;
}

.custom-table {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.column-heading {
  border-bottom: 1px solid #ddd;
}

.header-row,
.data-row {
  border-bottom: 1px solid #ddd;
}

.header-row {
  font-weight: bold;
}

.last-row {
  border-bottom: none;
}
.header-column {
  color: #797979;
}

.ion-router-link:hover {
  color: #23527c !important;
  text-decoration: underline !important;
}
