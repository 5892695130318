ion-card {
  --height: auto !important;
}
.swal-button-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 15px;
}

.button-text-normal {
  text-transform: none;
}

.ion-bagde-align {
  margin: 5px;
  border-radius: 50%;
  height: auto;
  width: auto;
  padding: 2px 4px 2px 4px;
}

.div-allign{
  display:flex;
  justify-content:center;
  align-items: center;
  gap: 2px;
}
