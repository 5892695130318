.horizontal-line {
    height: 1px;
    background-color: #646060;
    margin: 16px 0;
  }

.text-small {
    font-size: 14px;
  }

.custom-btn {
  background-color: white;
  font-size: 22px;
}

.dark-mode-font-color{
  background-color: rgb(18 18 18);
  color: white
  }
