.even-row-background {
  background-color: #fff;
}

.odd-row-background {
  background-color: #f3f5f6;
}

.custom-col {
  font-family: "Roboto, sans-serif";
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 10px;
  color: #797979;
  font-weight: 400;
}

.custom-action-bar {
  text-align: center;
}

.custom-row-header {
  border-bottom: 1px solid #ddd;
}

.custom-header-cell {
  font-family: "Roboto, sans-serif";
  font-size: 14px;
  background: white;
  padding: 10px;
  color: #797979;
  border: 1px solid #ddd;
  font-weight: bold;
}

.custom-data-row {
  font-family: "Roboto, sans-serif";
  font-size: 14px;
}

.custom-alert {
  background: #c5eafa;
  height: fit-content;
  padding: 15px;
  margin: 20px;
  width: 650px;
}

.custom-alert-dark {
  background: #1e1e1e;
  height: fit-content;
  padding: 15px;
  margin: 20px;
  width: 650px;
}

.alert-text-white {
  color: white;
}

.custom-grid {
  width: auto;
  margin: 10px;
  overflow-x: auto;
  border-collapse: collapse;
}

ion-toolbar.custom-toolbar.md.in-toolbar .toolbar-container {
  background-color: inherit;
}

.bordered-cell {
  border: 1px solid #ddd;
}

.table-container {
  overflow-x: auto;
}
.toolbar-background {
  background-color: inherit !important;
}

.font-size-icon {
  font-size: 14px;
}
.font-size-button {
  font-size: 10px;
}

.clickable-icon {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .large-screen {
    width: 100vw;
  }
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.gap-5 {
  gap: 5px;
}

.text-success {
  color: #34c73b;
}

.text-disabled {
  color: #6c757d;
}

.text-danger {
  color: #d74548;
}

.action-bar-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: start;
  gap: 10px;
}

.action-icon {
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}
