.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

/* Navbar Header */
.navbar-header {
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  z-index: 999;
}

/* Navbar Content */
.navbar-content {
  display: flex;
  justify-content: space-between;
  margin: 4px;
  gap: 8px;
}

/* Navbar Left */
.navbar-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  padding: 0px 1.7vw;
  max-width: 170px;
  width: 100%;
  height: auto;
}

/* Navbar Title */
.navbar-title {
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: "#000000";
}

/* Navbar Right */
.navbar-right {
  display: flex;
  align-self: center;
}

.info-container {
  cursor: default;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
  margin: auto;
  padding: 6px;
}

/* Navbar Link */
.navbar-link {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
  margin: auto;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 6px;
  text-decoration: none !important;
  position: relative;
  font-weight: 600;
}

.navbar-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: transparent;
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.navbar-link:hover {
  color: #000;
}

.navbar-link:hover::before {
  width: 100%;
  background-color: #000;
}

a:-webkit-any-link {
  text-decoration: none !important;
}

/* Navbar Dropdown */
.navbar-dropdown {
  display: flex;
  align-self: center;
}

@media screen and (max-width: 512px) {
  .navbar-content {
    display: flex !important;
    flex-direction: column !important;
  }
}

.user-icon:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.user-icon:hover {
  transform: scale(1.1);
}

.user-image {
  height: 40px;
  width: 40px;
  border: 2px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-image-icon {
  height: 40px;
  width: 40px;
  border: 2px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.navbar-font-color{
color: white
}

.dark-mode-color{
  background-color: #242424;
}

.light-mode-color{
  background-color: #2f353f;
}
