.table-container {
  overflow-x: auto;
  border: 1px solid #ddd; /* Add border to the table container */
  border-radius: 5px; /* Add border radius for a card-like appearance */
  margin: 20px; /* Add margin to create space around the table */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
}

table {
  width: 100%;
  border-collapse: collapse; /* Collapse table borders to prevent double borders */
}

th, td {
  padding: 12px;
  text-align: left; /* Align table cell content to the left */
  border-bottom: 1px solid #ddd; /* Add border to table rows */
}

.table-header {
  font-weight: bold;
  background-color: #f5f5f5; /* Add a background color to the header row */
}

.dark-mode-color{
  background-color: #242424;
}
