.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 3px 6px;
  max-width: fit-content;
  border-radius: 4px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltiptext.light {
  background-color: #fff;
  color: #000;
}

.tooltip .tooltiptext.dark {
  background-color: #333;
  color: #fff;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
